/* responsive media css start here */
@media only screen and (max-width: 1399px) {
    .footer_section .col-4 {width: 40.333333%;}
}
@media only screen and (max-width: 1280px) {
    .posterlistwraps .poserrbooksrow .col-md-2.col-sm-4.col-xs-12 {width: 21.666667%;}
    .paymentforms.paymentFormsWrapper {max-height: calc(100vh - 320px) !important;overflow: auto;}
}
@media only screen and (max-width: 1201px) {
    .container.pravcypolicycontainer {float: none;}
    .container {float: left; width: 100%; max-width: 100%;}
    .row {float: none;display: table;margin: 0 auto;}
    .posterlistwraps .poserrbooksrow .col-md-2.col-sm-4.col-xs-12 {width: 24.666667%;}
    .chosseCustomNumber {width: 100%;}
    .choosebundleModal h1 {font-size: 38px;}
}
@media only screen and (max-width: 1021px) {
    .header_section .logowraphead img {width: 170px;}
    .wrapper {padding-top: 135px;}
}
@media only screen and (max-width: 991px) {
    .checkoutpaymnt {width: 100%;padding: 0px;}
    .headermainwrps {padding: 0px;}
    .headerLinks ul li a {font-size: 21px;}
    .header_section .logowraphead img {width: 135px;}
    .wrapper {padding-top: 108px;}
    .commonheader h1 {font-size: 36px;}
    .commonheader h4 {font-size: 64px;}
    .purchesswraps {float: left;width: 100%;}
    .header_section .logowraphead{width: auto;}
    .footer_section .col-md-5 {width: 50%;}
    .footer_section .col-4 {width: 50%;}
}
@media only screen and (max-width: 900px) {
    .bookspreviewModal .closePreviewModal {width: 22px;height: 22px;right: 15px;top: 15px;}
    .bookspreviewModal {width: 100%;padding: 20px 15px;}
    .PurchesPostersction .purchesswraps .col-6 {width: 100%;}
    .posterlistwraps .poserrbooksrow .col-md-2.col-sm-4.col-xs-12 {width: 32.666667%;padding: 0px 10px !important;}
    .purchespassage {margin-bottom: 50px;}
}
@media only screen and (max-width: 840px) {
    .chosseCustomNumber {width: 100%;display: block;}
    .chosseCustomNumber .formgroup {width: 48%;float: left;margin-bottom: 15px;}
}
@media only screen and (max-width: 800px) {
    .CommonDropdownWraps {right: auto;left: 0;}
    .btn.resetbtn {padding: 8px 9px;font-size: 15px;width: auto;}
    .searchfilterbookswraps select {width: 260px;height: 41px;padding: 5px;font-size: 14px;}
    .searchfilterbookswraps h4{font-size: 17px;}
    .sectionheader {font-size: 30px;}
    .header_section .logowraphead {width: auto !important;float: none;margin: 0 auto;}
    .dropdousericon {margin: 0px 18px -3px 0px !important;}
    .responsiveSidebar {padding: 30px 20px;}
}
@media only screen and (max-width: 767px) {
    .closeModal {z-index: 99;}
    .paymentheadermain {margin: 6px 0 13px;}
    .pymenttitle {margin: 0 0 20px;padding: 13px 46px 0px 20px;}
    .CheckoutModalWrapper {padding: 0px;width: 100%;}
    .CheckoutModalWrapper .paymentWrappers {padding: 0px 20px;}
    .paymentheadermain h4 {padding-left: 20px;}
    .paymentforms {max-height: calc(100vh - 245px) !important;}
    .dropdowncustome .amountdetailsdropdown {right: -15px;}
    .paymentforms.paymentFormsWrapper {max-height: calc(100vh - 245px) !important;}
    .myaccountprofilepic h4 {font-size: 23px;}
    .myaccountprofilepic {background-color: rgb(19, 99, 145);height: 170px; margin: 0 0 135px;padding: 25px 33px;}
    .acctprofile {float: left;width: 120px;height: 120px;margin-bottom: 20px;background-color: #fff;}
    .checkoulistwrps {width: 100%;margin-bottom: 40px;}
    .profileacountset {float: left;width: 100%;text-align: left;margin: 80px 0 0;}
    .checkoutpaymnt h1 {font-size: 34px;margin: 0 0 20px;}
    .singlepostersection.PosterBundleswraper .purchesBundletypes {width: 100%;}
    .singlepostersection.PosterBundleswraper .purchesswraps .col-md-6:last-child .purchesBundletypes {margin: 0px;}
    .commonheader h1 {font-size: 33px;line-height: 55px;}
    .commonheader h4 {font-size: 50px;}
}
@media only screen and (max-width: 700px) {
    .commonpopup {width: 95%;padding: 40px 32px;}
    .footer_section .col-md-5, .footer_section .col-4 {width: 100%;}
    .footermailjoin p {margin: 20px 0 20px;}
    .choosebundleModal h1 {font-size: 28px;margin: 0 0 45px;}
    .chosseCustomNumber .formgroup .CustomRadio { font-size: 22px;}
    .privacypage.RefundPolicypage {padding: 40px 0px;}
}
@media only screen and (max-width: 640px) {
    .contactDetails {margin: 0 0 30px;}
    .privacypage h1 {font-size: 25px;margin: 0px 0px 15px;}
    .privacypage p {
        font-size: 13px;
    }
    .privacypage.RefundPolicypage h4 {
        font-size: 15px;
    }
    .privacypage.RefundPolicypage h4 {font-size: 15px;}
    .contactDetails div {font-size: 13px;margin: 0 5px;}
}
@media only screen and (max-width: 600px) { 
    .contactussubmit .formcomnbtn {width: 100%;padding: 8px 50px;}
    .bookspreviewModal .closePreviewModal {width: 18px;height: 18px;right: 19px;top: 19px;}
    .booksummrysetwrapper h4 {margin-bottom: 20px;font-size: 21px;}
    .booksummryset p {font-size: 14px;float: left;width: 100%;word-break: break-word;}
    .checkoutlistwrapper .checkoutlist {flex-direction: column;align-items: flex-start;}
    .footercopyright {font-size: 13px;}
    .Toastify__toast-container {width: 92% !important;top: 15px !important;left: 0 !important;right: 0 !important;margin: 0 auto !important;}
    .footermailjoin .footemialform .form-group {flex-direction: column; }
    .footermailjoin .footemialform .form-group input {margin: 0 0 10px;font-size: 15px !important;width: 100%;}
    .footermailjoin .danger-colorset {margin: 0px 0 10px;}
    .footermailjoin .footemialform .form-group .btn, .footermailjoin .footemialform .form-group .btn:focus {margin-top: 10px;width: 100%;}
    .myaccountwrpsheader .headertitle h4 {font-size: 28px;}
    .myaccountwrps {padding: 12px 12px;}
    .container.custom_container_new {width: 100%;}
    .commonheader {padding: 0px 10px;}
    .checkoulistwrps h2 {font-size: 24px;}
    .checkoutlistwrapper .checkoutlist h5 {font-size: 22px;}
    .checkoutlistwrapper .checkoutlist .previewImage img {width: 140px;height: 140px;margin: 0 0 15px 0px;}
    h1.sectionheaders {font-size: 30px;}
    .footer_section .col-md-5 .col-6 {width: 100%;}
    .posterlistwraps .poserrbooksrow .col-md-2.col-sm-4.col-xs-12{width: 100%;}
    .searchfilterbookswraps {flex-direction: column;}
    .searchfilterbookswraps select{margin: 10px 0 10px 0;}
    .searchfilterbookswraps h4{margin: 0px;}
    .responsiveSidebar {width: 100%;}
    .successpymentwrapper  {width: 90%;}
    .successpymentwrapper {width: 90%;padding: 50px 10px 0;}
}
@media only screen and (max-width: 540px) {
    .loginsignuptabs h1 {font-size: 28px;margin: 0 0 20px;}
    .loginPopupMain {width: 93%;font-size: 28px;margin: 0 auto 20px;}
    .Purchasebtn, .Purchasebtn:focus {font-size: 10px;}
    .commonheader h4 {margin: 55px 0px 0px;}
    .digitalposterssliders {padding: 60px 0px 60px;}
    .cancelmodal, .cancelmodal:focus {padding: 7px 25px;}
    .discardmodal, .discardmodal:focus {padding: 7px 25px;}
    .paymentssteps {column-gap: 0px !important;}
    .myaccountwrpsheader .poserrbooksrow .posterblogswrp {flex-direction: column;}
    .myaccountwrpsheader .poserrbooksrow .posterblogswrp .previewImage, .myaccountwrpsheader .poserrbooksrow .posterblogswrp img {width: 100%;height: auto;}
}
@media only screen and (max-width: 500px) {
    .PurchesPostersction .purchesswraps .col-6 {padding: 0px;}
    .commonheader h1 {font-size: 28px;line-height: 41px;}
    .commonheader h4 {font-size: 36px;}
    .clickpaymnt, .clickpaymnt:focus {font-size: 15px;}
    .paymentforms .rowcustom:last-child {margin-bottom: 20px;}
    .rowcustom {margin-bottom: 10px;}
    .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {width: 30.33% !important;}
    .dropdowncustome .amountdetailsdropdown {width: 96%;position: fixed;left: 0;right: 0;float: none;margin: 0 auto;top: 50px;}
    .digitalposterssliders {padding: 36px 0px 0px;}
    .rowcustom-col-2 {flex-direction: column;}
    .rowcustom.rowcustom-col-2 .form-group:first-child {margin-bottom: 10px;}
    .purchesBundleGroups {width: 331px;}
    .singlepostersection.PosterBundleswraper .purchesBundletypes .Purchasebtnbig {width: 100%;font-size: 11px;padding: 12px 4px;}
    .purchesswraps .col-md-6 {padding: 0px;}
    .succesiocn {width: 80px;height: 80px;top: -30px;}
    .successpymentbutns .btn {padding: 11px 12px;font-size: 13px;border-radius: 8px;}
    .successpymentbutns {padding: 18px 0px 18px;}
    .successpymentwrapper h4 {font-size: 20px;margin: 10px 0 10px;}
    .successpymentwrapper p {float: left;width: 100%;font-size: 13px;line-height: 24px; margin: 0px auto 10px;}
    .tarnsitionId {font-size: 12px;}
}
@media only screen and (max-width: 440px) {
    .chosseCustomNumber .formgroup {width: 100%;}
}
@media only screen and (max-width: 400px) {
    .purchesBundleGroups {width: 100%;}
}